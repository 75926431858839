export const COLOR = {
  BLACK: '#0D0D0D',
  DARKGRAY: '#999999',
  GRAY: '#CBCBCB',
  LIGHTGRAY: '#EAEAEA',
  WHITE: '#FFFFFF',
  OFFWHITE: '#F8F8F8',

  DARKGREEN: '#008F6B',
  DARKGREEN2: '#00AE8A',
  GREEN: '#00D0AA',
  LIGHTGREEN: '#A9F6E3',
  LIGHTGREEN2: '#DDFFF7',

  DARKPINK: '#B23955',
  PINK: '#FF527A',
  LIGHTPINK: '#FFEBF0',
  LIGHTPINK2: '#FDDEE6',

  DARKYELLOW: '#D29101',
  YELLOW: '#FFC648',
  LIGHTYELLOW: '#FFE7B2',
  LIGHTYELLOW2: '#FFEFCC',
  LEMON: '#FFF700',

  LIGHTBLUE: '#56CCF2',
  BLUE: '#0866FF',

  ORANGE: '#F2994A',

  // ANOTHERDOT COLOR DESIGN SYSTEM
  // PRIMARY BLUE
  Blue_50: '#EAF3FF',
  Blue_100: '#D9E8FF',
  Blue_200: '#BAD4FF',
  Blue_300: '#90B6FF',
  Blue_400: '#648BFF',
  Blue_500: '#4160FF',
  Blue_600: '#2032FF',
  Blue_700: '#1422ED',
  Blue_800: '#1421BF',
  Blue_900: '#1A2695',
  Blue_950: '#0F1457',

  // SECONDARY
  Purple_50: '#EFF0FE',
  Purple_700: '#573EC4',
  Purple_L: '#D0D3FB',
  Purple_M: '#979DF7',

  // NEUTRAL
  White: '#FFFFFF',
  Black: '#010101',
  Black_L: '#1E1E1E',
  Gray_D: '#6D6D6D',
  Gray_M: '#9D9D9D',
  Gray_50: '#F6F6F6',
  Gray_100: '#EEEFEF',
  Gray_200: '#E3E4E5',
  Gray_300: '#CED0D1',
  Gray_400: '#B9BBBD',

  // SUCCESS
  Green_D: '#115B43',
  Green_M: '#009262',
  Green_L: '#F1FBF8',
  Forest: '#1B6E53',
  Herbal: '#32C997',
  Mint: '#84DFC1',

  // Warning
  Yellow_D: '#E48900',
  Yellow_M: '#FFB240',
  Yellow_L: '#FFF8EC',
  Lemon: '#FFA826',
  Straw: '#FFC670',
  Sand: '#FFD596',

  // Danger
  Red_D: '#C33025',
  Red_M: '#E02B1D',
  Red_L: '#FFF1F0',
  Scarlet: '#E01507',
  Raspberry: '#FF5A4F',
  Rubicund: '#F0857D',
  Warning: '#FF1200',

  // TBD COLORs : unknown or gradient yet to be replaced
  PURPLE: '#573EC4',
  LIGHTPURPLE: '#D0D3FB',

  // Platforms
  Facebook: '#1877F2'
};
