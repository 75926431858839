import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { PlatformType } from '../../types/deal';
import Facebook from '../../assets/images/registerFrom/Facebook.svg';

interface DimensionProps {
  dimension: number;
}

interface Props {
  platform: PlatformType;
  iconSize: number;
  dimension: number;
}

const RPlatformIcon = (props: Props) => {
  const { platform, iconSize, dimension } = props;
  switch (platform) {
    case 'INSTAGRAM':
      return (
        <InstagramContainer dimension={dimension}>
          <RXIcon name="Instagram" color={COLOR.White} size={iconSize} />
        </InstagramContainer>
      );
    case 'TIKTOK':
      return (
        <TiktokContainer dimension={dimension}>
          <RXIcon name="Tiktok" color={COLOR.White} size={iconSize} />
        </TiktokContainer>
      );
    case 'LEMON':
      return (
        <Image
          src={require('../../assets/images/PlatformLogos/Lemon.png')}
          dimension={dimension}
        />
      );
    case 'FACEBOOK':
      return (
        <>
          <FacebookIconContainer
            className="facebook-icon-container"
            style={{ width: dimension, height: dimension }}
          >
            <Facebook name="Facebook" width={dimension} height={dimension} />
          </FacebookIconContainer>
        </>
      );
    default:
      return <></>;
  }
};

export default RPlatformIcon;

const InstagramContainer = styled.div<DimensionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.dimension}px;
  height: ${props => props.dimension}px;
  border-radius: 50px;
  background: linear-gradient(
      225deg,
      #4f5bd5 0.62%,
      #962fbf 23.93%,
      #d62976 48.93%,
      #fa7e1e 70.81%,
      #feda75 96.33%
    )
    border-box;
`;

const TiktokContainer = styled.div<DimensionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.dimension}px;
  height: ${props => props.dimension}px;
  border-radius: 50px;
  background: ${COLOR.Black};
`;

const FacebookIconContainer = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${COLOR.White};
`;

const Image = styled.img<DimensionProps>`
  width: ${props => props.dimension}px;
  height: ${props => props.dimension}px;
  border-radius: 24px;
`;
