import styled from 'styled-components';
import { COLOR } from '../fonts/color';
import { PlatformType } from '../types/deal';
import Facebook from '../assets/images/PlatformLogos/Facebook.svg';

interface PlatformLogoProps {
  platformType: PlatformType;
  width?: string;
  height?: string;
}

const PlatformLogo = ({
  platformType,
  width = '24px',
  height = '24px'
}: PlatformLogoProps) => {
  switch (platformType) {
    case 'INSTAGRAM':
      return (
        <Image
          src={require('../assets/images/PlatformLogos/Instagram.png')}
          width={width}
          height={height}
        />
      );
    case 'TIKTOK':
      return (
        <Image
          src={require('../assets/images/PlatformLogos/Tiktok.png')}
          width={width}
          height={height}
        />
      );
    case 'LEMON':
      return (
        <Image
          src={require('../assets/images/PlatformLogos/Lemon.png')}
          width={width}
          height={height}
        />
      );
    case 'FACEBOOK':
      return (
        <Facebook
          name="FacebookCircle"
          color={COLOR.Blue_700}
          width={width}
          height={height}
          fill={COLOR.Facebook}
        />
      );
    default:
      return null;
  }
};

export default PlatformLogo;

const Image = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 20px;
`;
