import styled from 'styled-components';
import { PlatformType } from '../types/deal';
import RPlatformIcon from '../components/atoms/RPlatformIcon';
import { COLOR } from '../fonts/color';

type ProfileWithPlatfromProps = {
  profileImage: string;
  platform: PlatformType;
};
const ProfileWithPlatfrom = (props: ProfileWithPlatfromProps) => {
  const { profileImage, platform } = props;
  return (
    <ImageWithIconContainer className="influencer-profile-with-platform">
      <Image src={profileImage} />
      <PlatformIconContainer>
        <RPlatformIcon platform={platform} iconSize={12} dimension={15} />
      </PlatformIconContainer>
    </ImageWithIconContainer>
  );
};

export default ProfileWithPlatfrom;

const ImageWithIconContainer = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 36px;
`;

const PlatformIconContainer = styled.div`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1.5px solid ${COLOR.Gray_100};
  position: absolute;
  right: -5px;
  bottom: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
