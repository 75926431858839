import { adsDuration } from './../components/organisms/CreateCampaign/Options';
import { PostTypes, Work } from './work';
import { PostMedia } from '../class/ScopeOfWork';
import { influencerCaption, documentType, tierType } from './createDeal';
import { ImageProp } from '../components/organisms/CreateCampaign/ImageSelector';
import { InfluencerPost } from './api/getInfluencer';
interface DealAnalysis {
  dealId: string;
  dealName: string;
  dealCash: number;
  dealValue: number;
  workReceived: number;
  workReceivedInfluencers: number;
  totalFollowers: number;
  totalInfluencers: number;
  engagements: number;
  likes: number;
  comments: number;
  replies: number;
  saved: number;
  engagementRate: number;
  engagementRateFormular: string;
  reachs: number;
  storyReachs: number;
  postReachs: number;
  impressions: number;
  storyImpressions: number;
  postImpressions: number;
  totalBudgetSpent: number;
  engagementPerBaht: number;
  reachPerBaht: number;
  bahtPerEngagement: number;
  bahtPerReach: number;
  IG_individual_post: number;
  IG_individual_post_engagements: number;
  IG_individual_post_reachs: number;
  IG_individual_post_likes: number;
  IG_individual_post_comments: number;
  IG_individual_post_avgEngagementPerPost: number;
  IG_individual_post_impressions: number;
  IG_individual_post_saved: number;
  IG_album_post: number;
  IG_album_post_engagements: number;
  IG_album_post_reachs: number;
  IG_album_post_likes: number;
  IG_album_post_comments: number;
  IG_album_post_impressions: number;
  IG_album_post_avgEngagementPerPost: number;
  IG_album_post_saved: number;
  IG_reels: number;
  IG_reels_engagements: number;
  IG_reels_reachs: number;
  IG_reels_likes: number;
  IG_reels_comments: number;
  IG_reels_impressions: number;
  IG_reels_avgEngagementPerPost: number;
  IG_reels_saved: number;
  IG_story: number;
  IG_story_storyView: number;
  IG_story_avgViewPerStory: number;
  IG_story_replies: number;
  TK_video_post: number;
  TK_video_videoViews: number;
  TK_video_like: number;
  TK_video_comment: number;
  TK_video_share: number;
  updatedAt: Date;
}

export interface Deal {
  posts: PostTypes & {
    isExamples?: boolean;
    exampleDetail: string;
    examples: string[];
    postMedias: PostMedia[];
    isTiktokExamples?: boolean;
    tiktokExamples?: string[];
    tiktokExampleDetail?: string;
    isFacebookExamples?: boolean;
    facebookExamples?: stringp[];
    facebookExampleDetail?: string;
  };
  criteria: {
    ages: string;
    gender: string;
    tiktokMinFollower?: number;
    minFollower?: number;
    facebookMinFollower?: number;
  };
  tiktokHashtags: string[];
  tiktokCaptionGuide: string;
  facebookHashtags: string[];
  facebookCaptionGuide: string;
  hashtags: Array<string>;
  secret: boolean;
  brandApprove: boolean;
  benefits: Array<string>;
  benefitsEditted?: boolean;
  targetInfluencers: Array<string>;
  photos: Array<string>;
  pendingContacts: Array<string>;
  offerContacts: Array<string>;
  counterPendingContacts: Array<string>;
  cancelledContacts: Array<string>;
  rejectedContacts: Array<string>;
  ongoingContacts: Array<string>;
  checkContacts: Array<string>;
  doneContacts: Array<string>;
  active: boolean;
  negotiation: boolean;
  isNegotiateScopeOfWorks: boolean;
  isNegotiateCash: boolean;
  isNegotiateProductValue: boolean;
  name: string;
  nameEditted?: boolean;
  gender: string;
  age: string;
  location: string;
  category: string;
  value: number;
  valueEditted?: boolean;
  cash: number;
  cashEditted?: boolean;
  limit: number;
  keyMessage: string;
  startDate: Date;
  postingDate: Date;
  submissionDate: Date;
  dueDate: Date;
  createdDate: Date;
  caption: string;
  detail: string;
  detailEditted: boolean;
  productDetail: string;
  productDetailEditted: boolean;
  brandId: string;
  dealId: string;
  createdAt: Date;
  updatedAt: Date;
  analysis?: DealAnalysis;
  coverImg?: string;
  pendingSize: number;
  counterPendingSize: number;
  ongoingSize: number;
  checkSize: number;
  doneSize: number;
  notiPending?: number;
  notiOngoing?: number;
  notiCheck?: number;
  notiDone?: number;
  platforms: ['Instagram' | 'Tiktok' | 'Lemon' | 'Facebook'];
  brand?: {
    companyName: string;
    img: string;
    name: string;
  };
  pastInfluencers?: Array<{
    id: string;
    profile_picture_url: string;
    username: string;
  }>;
  tags?: Array<'High Reach' | 'Popular'>;
  total?: number;
  examples: ImageProp[];
  influencersTier: {
    nano: {
      value: number;
      cash: number;
    };
    micro: {
      value: number;
      cash: number;
    };
    mid: {
      value: number;
      cash: number;
    };
    macro: {
      value: number;
      cash: number;
    };
  };
  influencersCaption: influencerCaption;
  mustReturnProduct: boolean;
  reviewTag: string[];
  ads: {
    startDate: string | null;
    endDate: string | null;
    value: adsDuration;
  };
  attribute: string[];
  documents: documentType[];
  version: number;
}

export type TPostTypes = PostTypes & {
  exampleDetail: string;
  examples: Array<string>;
  postMedias: PostMedia[];
};

export interface FeedDeal {
  total: number;
  tags: Array<'High Reach' | 'Popular' | undefined>;
  pastInfluencers: {
    id: string;
    profile_picture_url: string;
    username: string;
  }[];
  works: Work[];
  followers?: number;
  tiktokFollowers?: number;
  facebookFollowers?: number;
}

export type PlatformType = 'INSTAGRAM' | 'TIKTOK' | 'LEMON' | 'FACEBOOK';

export type PlatformWithTotalType = PlatformType | 'ALL';

export type StatusType = 'pending' | 'ongoing' | 'check' | 'done';

export const initateDeal: Deal = {
  posts: {
    IG_individual_post: 0,
    IG_album_post: 0,
    IG_album_images: 0,
    IG_tv: 0,
    IG_story: 0,
    IG_reels: 0,
    FB_individual_post: 0,
    FB_album_post: 0,
    FB_album_images: 0,
    FB_reels: 0,
    TK_video: 0,
    LM_article: 0,
    exampleDetail: '',
    examples: new Array<string>(),
    postMedias: new Array<PostMedia>(),
    tiktokExamples: new Array<string>(),
    facebookExamples: new Array<string>()
  },
  criteria: {
    ages: '',
    gender: '',
    tiktokMinFollower: 0,
    minFollower: 0,
    facebookMinFollower: 0
  },
  tiktokHashtags: new Array<string>(),
  tiktokCaptionGuide: '',
  facebookHashtags: new Array<string>(),
  facebookCaptionGuide: '',
  hashtags: new Array<string>(),
  secret: false,
  brandApprove: false,
  benefits: new Array<string>(),
  benefitsEditted: false,
  targetInfluencers: new Array<string>(),
  photos: new Array<string>(),
  pendingContacts: new Array<string>(),
  offerContacts: new Array<string>(),
  counterPendingContacts: new Array<string>(),
  cancelledContacts: new Array<string>(),
  rejectedContacts: new Array<string>(),
  ongoingContacts: new Array<string>(),
  checkContacts: new Array<string>(),
  doneContacts: new Array<string>(),
  active: false,
  negotiation: false,
  isNegotiateScopeOfWorks: false,
  isNegotiateCash: false,
  isNegotiateProductValue: false,
  name: '',
  nameEditted: false,
  gender: '',
  age: '',
  location: '',
  category: '',
  value: 0,
  valueEditted: false,
  cash: 0,
  cashEditted: false,
  limit: 0,
  keyMessage: '',
  startDate: new Date(),
  postingDate: new Date(),
  submissionDate: new Date(),
  dueDate: new Date(),
  createdDate: new Date(),
  caption: '',
  detail: '',
  detailEditted: false,
  productDetail: '',
  productDetailEditted: false,
  brandId: '',
  dealId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  analysis: {
    dealId: '',
    dealName: '',
    dealCash: 0,
    dealValue: 0,
    workReceived: 0,
    workReceivedInfluencers: 0,
    totalFollowers: 0,
    totalInfluencers: 0,
    engagements: 0,
    likes: 0,
    comments: 0,
    replies: 0,
    saved: 0,
    engagementRate: 0,
    engagementRateFormular: '',
    reachs: 0,
    storyReachs: 0,
    postReachs: 0,
    impressions: 0,
    storyImpressions: 0,
    postImpressions: 0,
    totalBudgetSpent: 0,
    engagementPerBaht: 0,
    reachPerBaht: 0,
    bahtPerEngagement: 0,
    bahtPerReach: 0,
    IG_individual_post: 0,
    IG_individual_post_engagements: 0,
    IG_individual_post_reachs: 0,
    IG_individual_post_likes: 0,
    IG_individual_post_comments: 0,
    IG_individual_post_avgEngagementPerPost: 0,
    IG_individual_post_impressions: 0,
    IG_individual_post_saved: 0,
    IG_album_post: 0,
    IG_album_post_engagements: 0,
    IG_album_post_reachs: 0,
    IG_album_post_likes: 0,
    IG_album_post_comments: 0,
    IG_album_post_impressions: 0,
    IG_album_post_avgEngagementPerPost: 0,
    IG_album_post_saved: 0,
    IG_reels: 0,
    IG_reels_engagements: 0,
    IG_reels_reachs: 0,
    IG_reels_likes: 0,
    IG_reels_comments: 0,
    IG_reels_impressions: 0,
    IG_reels_avgEngagementPerPost: 0,
    IG_reels_saved: 0,
    IG_story: 0,
    IG_story_storyView: 0,
    IG_story_avgViewPerStory: 0,
    IG_story_replies: 0,
    TK_video_post: 0,
    TK_video_videoViews: 0,
    TK_video_like: 0,
    TK_video_comment: 0,
    TK_video_share: 0,
    updatedAt: new Date()
  },
  coverImg: '',
  pendingSize: 0,
  counterPendingSize: 0,
  ongoingSize: 0,
  checkSize: 0,
  doneSize: 0,
  notiPending: 0,
  notiOngoing: 0,
  notiCheck: 0,
  notiDone: 0,
  platforms: new Array<'Instagram' | 'Tiktok' | 'Facebook'>(),
  examples: [],
  influencersTier: {
    nano: {
      value: 0,
      cash: 0
    },
    micro: {
      value: 0,
      cash: 0
    },
    mid: {
      value: 0,
      cash: 0
    },
    macro: {
      value: 0,
      cash: 0
    }
  },
  influencersCaption: {
    nano: '',
    micro: '',
    mid: '',
    macro: ''
  },
  mustReturnProduct: false,
  reviewTag: [],
  ads: {
    startDate: '',
    endDate: '',
    value: 'none'
  },
  attribute: [],
  documents: []
};

export interface DealPerformanceTableData {
  username: string;
  profileImage: string;
  posts: DealPerformancePost[];
  tier: tierType;
  status: 'ongoing' | 'done';
  budget: number;
  totalEngagement: number;
  totalReach: number;
  totalImpression: number;
  totalCPE: number;
  totalCPR: number;
  totalCPM: number;
  totalPosts: number;
}

export interface DealPerformancePost {
  image: string;
  username: string;
  caption: string;
  permalink: string;
  createdAt: Date;
  like: number;
  comment: number;
  share: number;
  type: scopeOfWorkType;
  engagement: number;
  cpe: number;
  reach: number;
  cpr: number;
  impression: number;
  cpm: number;
}

export interface DealPerformance {
  like: number;
  comment: number;
  replies: number;
  saved: number;
  impressions: number;
  engagement: number;
  reach: number;
  share: number;
  cpe: number;
  cpr: number;
  cpm: number;
}

export interface IDealsHistory {
  dealId: string;
  dealPlatform: DealPlatform;
  timestamp: Date;
  engagement: number;
  reachCount?: number;
  impressionCount?: number;
  likeCount: number;
  commentCount: number;
  shareCount?: number;
  viewCount?: number;
  savedCount?: number;
  replyCount?: number;
  metaData: {
    dealId: string;
  };
}

export interface DealsChartData {
  daily: {
    labels: Date[];
    engagement: number[];
    reach: number[];
    impression: number[];
    view: number[];
  };
  weekly: {
    labels: Date[];
    engagement: number[];
    reach: number[];
    impression: number[];
    view: number[];
  };
  monthly: {
    labels: Date[];
    engagement: number[];
    reach: number[];
    impression: number[];
    view: number[];
  };
}
